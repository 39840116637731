/**
 * Source: https://www.gatsbyjs.org/blog/2019-01-31-using-react-context-api-with-gatsby/
 */

import React from "react"
import Cookies from 'js-cookie'

const defaultState = {
  activeLang: 'en',
  isPreloaded: true
};

let delay = 500;

const LangContext = React.createContext(defaultState);

class LangProvider extends React.Component {
  state = { ...defaultState };
  
  setActiveLang = (lang, home = false) => {
    Cookies.set('lang', lang, {path: '/'});
    if (home)
      delay = 100;
    this.timeoutId = setTimeout(() => {
      this.setState({
        activeLang: lang,
        isPreloaded: false
      });
    }, delay);
  };
  
  componentDidMount() {
    /** Deactivating the language switcher, for now
    let activeLang = Cookies.get('lang') || 'en';
    if (activeLang !== 'en' && activeLang !== 'hu') */
    let activeLang = 'en';
    this.setState({
      activeLang: activeLang,
      isPreloaded: true
    });
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, delay);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
  
  render() {
    const { children } = this.props;
    const { activeLang, isPreloaded } = this.state;
    return (
      <LangContext.Provider
        value={{
          activeLang,
          isPreloaded,
          setActiveLang: this.setActiveLang,
        }}
      >
        {children}
      </LangContext.Provider>
    )
  }
}
export default LangContext
export { LangProvider }
